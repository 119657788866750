/* eslint-disable */

import React, { useState, useEffect } from 'react';
import moment from 'moment';
import SiderComponent from './../components/SiderComponent';
import HeaderComponent from './../components/HeaderComponent';
import { formatedDealPeriodObject, refresh } from '../utils/Utils';
import { useSelector, useDispatch } from 'react-redux';
import {
  setGolfClubDetails,
  setTmpCourseId,
  setTmpDealCourses,
  setNewDealCourses,
} from '../redux/actions/golfClubActions';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { DeleteFilled, SaveFilled } from '@ant-design/icons';
import locale from 'antd/es/date-picker/locale/de_DE';
import {
  Layout,
  Form,
  Input,
  Button,
  Row,
  Col,
  InputNumber,
  Select,
  Checkbox,
  Tree,
  DatePicker,
  notification,
  Popconfirm,
} from 'antd';
import greenfeeDealsApi from '../api/greenfeeDeals';
const { Content } = Layout;
const { RangePicker } = DatePicker;

function Aufschlaege(props) {
  const options = [
    {
      label: 'VcG',
      value: 'VcG',
    },
    {
      label: 'Fernmitgliedschaft',
      value: 'Fernmitgliedschaft',
    },
    {
      label: 'Golfclub im Ausland',
      value: 'Golfclub im Ausland',
    },
  ]; // SESSION PARAMS
  const sessionBnd = localStorage.getItem('sessionBnd');
  const headers = {
    headers: { Authorization: `Bearer ${sessionBnd}` },
  };
  // REDUX STUFF
  const dispatch = useDispatch();
  const golfClubDetails = useSelector(
    (state) => state.golfClub.golfClubDetails
  );
  //const discountCards = useSelector((state) => state.general.discountCards);
  const membershipTypes = useSelector((state) => state.general.membershipTypes);
  const courses = useSelector((state) => state.golfClub.tmpDealCourses);
  const coursesNewDeal = useSelector((state) => state.golfClub.newDealCourses);
  const treeData = [];
  const treeDataNewDeal = [];
  let childrenNewDeal = [];
  golfClubDetails.courses.forEach((e) => {
    let subChildrenNewDeal = [];
    e.forEach((e2) => {
      subChildrenNewDeal.push({
        title: e2.standardPricing.dayFee
          ? `Tagesfee`
          : `${e2.standardPricing.numberHoles} Loch`,
        key: e2.standardPricing._id,
      });
    });
    childrenNewDeal.push({
      title: e[0].name,
      key: e[0]._id,
      children: subChildrenNewDeal,
    });
  });
  treeDataNewDeal.push({
    title: 'Alle Plätze',
    key: 'group',
    children: childrenNewDeal,
  });
  let treeDataIndex = 0;

  // SAFETY
  if (!golfClubDetails) {
    props.history.push(`/`);
    return <div></div>;
  }

  // FORM STUFF
  const [form] = Form.useForm();
  const { Option } = Select;
  const { DirectoryTree } = Tree;
  const dateFormat = 'DD-MM-YY';
  let dateRange = '';
  const golfClubId = golfClubDetails._id.golfClub;

  // TREE STUFF
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [checkedNewKeys, setCheckedNewKeys] = useState();
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [autoExpandParent, setAutoExpandParent] = useState(true);
  const onExpand = (expandedKeys) => {
    setExpandedKeys(expandedKeys);
    setAutoExpandParent(false);
  };

  const onCheckExisCourses = (keys, event) => {
    const filteredKeys = keys.filter((e) => e !== 'group');
    dispatch(setTmpDealCourses(filteredKeys));
  };
  const onCheckNewCourses = (keys, event) => {
    const filteredKeys = keys.filter((e) => e !== 'group');
    dispatch(setNewDealCourses(filteredKeys));
  };
  // UPDATE Deals
  const saveExisDeal = async (values) => {
    let deal = values.dealValues;
    console.log(deal);
    if (deal.discountType === '%') {
      deal.discountPercent = deal.discountAmount;
      deal.discountPrice = '';
    } else {
      deal.discountPrice = deal.discountAmount;
      deal.discountPercent = '';
    }
    if (values.typeValues) {
      deal.dealDetails = {};
      const name = values.typeValues;
      deal.name = name;
      deal.dealDetails.surcharge = name;
      deal.dealDetails.type = 'surcharge';
    }
    if (courses !== '') {
      deal.standardPricing = courses;
    }

    if (values.dealPeriod) {
      const dealPeriod = formatedDealPeriodObject(values.dealPeriod);
      deal.dealPeriod = dealPeriod;
    }

    try {
      const updateDeal = await greenfeeDealsApi.patch(
        `/golfclubs/${golfClubId}/deals/${deal._id}`,
        deal,
        headers
      );
      const golfClub = await greenfeeDealsApi.post(
        `/golfclubs/golfClubAdmin`,
        { id: golfClubId },
        headers
      );
      if (golfClub.data.status !== 'success' || golfClub.data.results === 0) {
        return;
      }
      const results = golfClub.data.data.data;

      // Update Redux Store
      dispatch(setGolfClubDetails(results));
      notification.success({
        message: 'Update Successful',
        description: 'The golf club details have been updated successfully.',
      });
      refresh();
    } catch (error) {}
  };
  // New Deals
  const saveNewDeal = async (values) => {
    console.log('values.typeValues', values.typeValues);
    let typeValues = values.typeValues;
    await Promise.all(
      typeValues.map(async (type) => {
        let newDeal = values.newDealValues;
        if (newDeal.discountType === '%') {
          newDeal.discountPercent = newDeal.discountAmount;
        } else {
          newDeal.discountPrice = newDeal.discountAmount;
        }
        newDeal.dealDetails = {};
        const name = type;
        newDeal.name = name;
        newDeal.dealDetails.surcharge = name;

        if (checkedNewKeys === undefined || checkedNewKeys[0] === 'group') {
          let newCourses = [];
          golfClubDetails.courses.forEach((e) => {
            newCourses.push(e[0]._id);
          });
          golfClubDetails.standardPricing.forEach((e2) => {
            newCourses.push(e2._id);
          });

          newDeal.standardPricing = newCourses;
        } else {
          newDeal.standardPricing = checkedNewKeys;
        }

        const dealPeriod = formatedDealPeriodObject(values.newDealPeriod);
        newDeal.dealPeriod = dealPeriod;
        newDeal.dealDetails.type = 'surcharge';

        await greenfeeDealsApi.post(
          `/golfclubs/${golfClubId}/deals`,
          newDeal,
          headers
        );
      })
    );

    try {
      const golfClub = await greenfeeDealsApi.post(
        `/golfclubs/golfClubAdmin`,
        { id: golfClubId },
        headers
      );
      if (golfClub.data.status !== 'success' || golfClub.data.results === 0) {
        return;
      }
      const results = golfClub.data.data.data;

      // Update Redux Store
      dispatch(setGolfClubDetails(results));
      form.resetFields();
      setCheckedNewKeys(['group']);
      notification.success({
        message: 'New Deal Save Successfully',
        description: 'The golf club new deal have been saved successfully.',
      });
    } catch (error) {}
  };
  //DELETE deal
  const deleteDeal = async (dealId) => {
    try {
      const postDeal = await greenfeeDealsApi.delete(
        `/golfclubs/${golfClubId}/deals/${dealId}`,
        headers
      );
      const golfClub = await greenfeeDealsApi.post(
        `/golfclubs/golfClubAdmin`,
        { id: golfClubId },
        headers
      );
      if (golfClub.data.status !== 'success' || golfClub.data.results === 0) {
        return;
      }
      const results = golfClub.data.data.data;

      // Update Redux Store
      dispatch(setGolfClubDetails(results));
      notification.success({
        message: 'Deleted Deal Successfully',
        description: 'The golf club deal have been deleted successfully.',
      });
    } catch (error) {}
  };
  useEffect(() => {
    setCheckedNewKeys(['group']);
    dispatch(setTmpDealCourses());
  }, []);

  const cancel = (e) => {
    console.log(e);
  };

  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };

  return (
    <div>
      <Layout style={{ minHeight: '100vh' }}>
        <SiderComponent props={props} />
        <Layout>
          <HeaderComponent />
          <Content
            style={{
              backgroundColor: '#EAEAEA',
              paddingLeft: 50,
              paddingRight: 50,
              paddingBottom: 50,
            }}
          >
            <h3 style={{ marginTop: 30 }}>Aufschläge</h3>
            <h5 style={{ marginTop: 30 }}>Neuen Aufschlag anlegen</h5>
            <div
              style={{
                backgroundColor: 'white',
                borderRadius: 15,
                width: '100%',
                padding: 30,
                marginTop: 20,
              }}
            >
              <Form
                onFinish={saveNewDeal}
                hideRequiredMark
                form={form}
                initialValues={{
                  newDealValues: {
                    dealDays: {
                      0: 'true',
                      1: 'true',
                      2: 'true',
                      3: 'true',
                      4: 'true',
                      5: 'true',
                      6: 'true',
                    },
                    discountType: '%',
                  },
                }}
              >
                <Row>
                  <Col span={7}>
                    <Row>
                      <Col style={{ width: 180 }}>
                        <Form.Item
                          name={'typeValues'}
                          rules={[
                            {
                              required: true,
                              message: 'Please select atleast one option',
                            },
                          ]}
                        >
                          <Select
                            size="small"
                            mode="multiple"
                            allowClear
                            style={{ width: '100%' }}
                            onChange={handleChange}
                            options={options}
                          />
                          {/* <Select size="small">
                            <Option value="VcG">VcG</Option>
                            <Option value="Fernmitgliedschaft">
                              Fernmitgliedschaft
                            </Option>
                            <Option value="Golfclub im Ausland">
                              Golfclub im Ausland
                            </Option>
                          </Select> */}
                        </Form.Item>
                      </Col>
                      <Col style={{ width: 50 }}>
                        <Form.Item name={['newDealValues', 'discountType']}>
                          <Select size="small">
                            <Option value="%">%</Option>
                            <Option value="€">€</Option>
                            <Option value="-">-</Option>
                            <Option value="+">+</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col>
                        <Form.Item name={['newDealValues', 'discountAmount']}>
                          <InputNumber size="small" style={{ width: 65 }} />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Form.Item name={['newDealPeriod']}>
                        <RangePicker
                          allowClear={false}
                          size="small"
                          format={dateFormat}
                          locale={locale}
                          style={{ width: 275 }}
                        />
                      </Form.Item>

                      <Form.Item
                          name={['newDealValues', 'rollingDeal']}
                          valuePropName="checked"
                          style={{ width: 30, marginLeft: 10 }}
                        >
                          <Checkbox className="bigCheck">
                            <h6 style={{ display: 'inline' }}>Rolling</h6>
                          </Checkbox>
                        </Form.Item>
                    </Row>
                  </Col>
                  <Col span={8} className="pricingCol">
                    <Row
                      align="center"
                      style={{
                        height: '100%',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <Col span={2} className="pricingCol" align="center">
                        <Form.Item
                          name={['newDealValues', 'dealDays', 0]}
                          valuePropName="checked"
                        >
                          <Checkbox className="bigCheck">
                            <h6 style={{ display: 'inline' }}>Mo</h6>
                          </Checkbox>
                        </Form.Item>
                      </Col>
                      <Col span={2} className="pricingCol" align="center">
                        <Form.Item
                          name={['newDealValues', 'dealDays', 1]}
                          valuePropName="checked"
                        >
                          <Checkbox className="bigCheck">
                            <h6 style={{ display: 'inline' }}>Di</h6>
                          </Checkbox>
                        </Form.Item>
                      </Col>
                      <Col span={2} className="pricingCol" align="center">
                        <Form.Item
                          name={['newDealValues', 'dealDays', 2]}
                          valuePropName="checked"
                        >
                          <Checkbox className="bigCheck">
                            <h6 style={{ display: 'inline' }}>Mi</h6>
                          </Checkbox>
                        </Form.Item>
                      </Col>
                      <Col span={2} className="pricingCol" align="center">
                        <Form.Item
                          name={['newDealValues', 'dealDays', 3]}
                          valuePropName="checked"
                        >
                          <Checkbox className="bigCheck">
                            <h6 style={{ display: 'inline' }}>Do</h6>
                          </Checkbox>
                        </Form.Item>
                      </Col>
                      <Col span={2} className="pricingCol" align="center">
                        <Form.Item
                          name={['newDealValues', 'dealDays', 4]}
                          valuePropName="checked"
                        >
                          <Checkbox className="bigCheck">
                            <h6 style={{ display: 'inline' }}>Fr</h6>
                          </Checkbox>
                        </Form.Item>
                      </Col>
                      <Col span={2} className="pricingCol" align="center">
                        <Form.Item
                          name={['newDealValues', 'dealDays', 5]}
                          valuePropName="checked"
                        >
                          <Checkbox className="bigCheck">
                            <h6 style={{ display: 'inline' }}>Sa</h6>
                          </Checkbox>
                        </Form.Item>
                      </Col>
                      <Col span={2} className="pricingCol" align="center">
                        <Form.Item
                          name={['newDealValues', 'dealDays', 6]}
                          valuePropName="checked"
                        >
                          <Checkbox className="bigCheck">
                            <h6 style={{ display: 'inline' }}>So/Ft</h6>
                          </Checkbox>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={5} className="pricingCol">
                    <Row>
                      <Col span={24} className="pricingCol">
                        <Form.Item name={['newDealValues', 'discountTerms']}>
                          <Input size="small" placeholder="Anmerkung" />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <DirectoryTree
                        checkable
                        selectable={false}
                        multiple
                        showIcon={false}
                        onCheck={(keys) => {
                          setCheckedNewKeys(keys);
                        }}
                        onExpand={onExpand}
                        treeData={treeDataNewDeal}
                        checkedKeys={checkedNewKeys}
                      />
                    </Row>
                  </Col>
                  <Col span={4} className="pricingCol">
                    <Row
                      style={{
                        alignItems: 'center',
                        height: '100%',
                      }}
                    >
                      <Col
                        span={20}
                        style={{
                          height: '100%',
                        }}
                      >
                        <Row
                          style={{
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '100%',
                          }}
                        >
                          <Button
                            type="primary"
                            htmlType="submit"
                            style={{
                              minWidth: 85,
                              minHeight: 40,
                            }}
                          >
                            Speichern
                          </Button>
                        </Row>
                      </Col>

                      <Col span={4}></Col>
                    </Row>
                  </Col>
                </Row>
              </Form>
            </div>
            <hr
              style={{
                border: '0.3px dotted rgba(0, 0, 0, 0.85)',
                marginTop: 30,
              }}
            />
            <h5 style={{ marginTop: 30 }}>Bestehende Aufschläge editieren</h5>
            {golfClubDetails.deals.map((deal, index) => {
              if (deal.dealDetails.type === 'surcharge') {
                if (deal.discountPercent) {
                  deal.discountType = '%';
                  deal.discountAmount = deal.discountPercent;
                } else {
                  if (!deal.discountType) {
                    deal.discountType = '€';
                    deal.discountAmount = deal.discountPrice;
                  } else {
                    deal.discountAmount = deal.discountPrice;
                  }
                }
                let dealPeriod = [];
                if (deal.dealPeriod) {
                  dealPeriod = [
                    moment(deal.dealPeriod.from),
                    moment(deal.dealPeriod.to),
                  ];
                }
                let children = [];
                golfClubDetails.courses.forEach((e) => {
                  let subChildren = [];
                  e.forEach((e2) => {
                    subChildren.push({
                      title: e2.standardPricing.dayFee
                        ? `Tagesfee`
                        : `${e2.standardPricing.numberHoles} Loch`,
                      key: e2.standardPricing._id,
                    });
                  });
                  children.push({
                    title: e[0].name,
                    key: e[0]._id,
                    children: subChildren,
                  });
                });

                treeData.push([
                  {
                    title: 'Alle Plätze',
                    key: 'group',
                    children: children,
                  },
                ]);
                const treeDataMap = treeData[treeDataIndex];
                treeDataIndex++;

                return (
                  <div
                    style={{
                      backgroundColor: 'white',
                      borderRadius: 15,
                      width: '100%',
                      padding: 30,
                      marginTop: 20,
                    }}
                    key={deal._id}
                  >
                    <Form
                      onFinish={saveExisDeal}
                      hideRequiredMark
                      initialValues={{
                        dealValues: deal,
                      }}
                    >
                      <Form.Item
                        name={['dealValues', '_id']}
                        className="noHeight"
                      >
                        <span />
                      </Form.Item>

                      <Row>
                        <Col span={7}>
                          <Row>
                            <Col style={{ width: 180 }}>
                              <Form.Item name={'typeValues'}>
                                <Select size="small" defaultValue={deal.name}>
                                  <Option value="VcG">VcG</Option>
                                  <Option value="Fernmitgliedschaft">
                                    Fernmitgliedschaft
                                  </Option>
                                  <Option value="Golfclub im Ausland">
                                    Golfclub im Ausland
                                  </Option>
                                </Select>
                              </Form.Item>
                            </Col>
                            <Col style={{ width: 50 }}>
                              <Form.Item name={['dealValues', 'discountType']}>
                                <Select defaultValue="%" size="small">
                                  <Option value="%">%</Option>
                                  <Option value="€">€</Option>
                                  <Option value="-">-</Option>
                                  <Option value="+">+</Option>
                                </Select>
                              </Form.Item>
                            </Col>
                            <Col>
                              <Form.Item
                                name={['dealValues', 'discountAmount']}
                              >
                                <InputNumber
                                  size="small"
                                  style={{ width: 65 }}
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row>
                            <Form.Item name={['dealPeriod']}>
                              <RangePicker
                                allowClear={false}
                                size="small"
                                format={dateFormat}
                                locale={locale}
                                defaultValue={dealPeriod}
                                style={{ width: 275 }}
                              />
                            </Form.Item>

                            <Form.Item
                              name={['dealValues', 'rollingDeal']}
                              valuePropName="checked"
                              style={{ width: 30, marginLeft: 10 }}
                            >
                              <Checkbox className="bigCheck">
                                <h6 style={{ display: 'inline' }}>Rolling</h6>
                              </Checkbox>
                            </Form.Item>
                          </Row>
                        </Col>

                        <Col span={8} className="pricingCol">
                          <Row
                            align="center"
                            style={{
                              height: '100%',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            <Col span={2} className="pricingCol" align="center">
                              <Form.Item
                                name={['dealValues', 'dealDays', 0]}
                                valuePropName="checked"
                              >
                                <Checkbox className="bigCheck">
                                  <h6 style={{ display: 'inline' }}>Mo</h6>
                                </Checkbox>
                              </Form.Item>
                            </Col>
                            <Col span={2} className="pricingCol" align="center">
                              <Form.Item
                                name={['dealValues', 'dealDays', 1]}
                                valuePropName="checked"
                              >
                                <Checkbox className="bigCheck">
                                  <h6 style={{ display: 'inline' }}>Di</h6>
                                </Checkbox>
                              </Form.Item>
                            </Col>
                            <Col span={2} className="pricingCol" align="center">
                              <Form.Item
                                name={['dealValues', 'dealDays', 2]}
                                valuePropName="checked"
                              >
                                <Checkbox className="bigCheck">
                                  <h6 style={{ display: 'inline' }}>Mi</h6>
                                </Checkbox>
                              </Form.Item>
                            </Col>
                            <Col span={2} className="pricingCol" align="center">
                              <Form.Item
                                name={['dealValues', 'dealDays', 3]}
                                valuePropName="checked"
                              >
                                <Checkbox className="bigCheck">
                                  <h6 style={{ display: 'inline' }}>Do</h6>
                                </Checkbox>
                              </Form.Item>
                            </Col>
                            <Col span={2} className="pricingCol" align="center">
                              <Form.Item
                                name={['dealValues', 'dealDays', 4]}
                                valuePropName="checked"
                              >
                                <Checkbox className="bigCheck">
                                  <h6 style={{ display: 'inline' }}>Fr</h6>
                                </Checkbox>
                              </Form.Item>
                            </Col>
                            <Col span={2} className="pricingCol" align="center">
                              <Form.Item
                                name={['dealValues', 'dealDays', 5]}
                                valuePropName="checked"
                              >
                                <Checkbox className="bigCheck">
                                  <h6 style={{ display: 'inline' }}>Sa</h6>
                                </Checkbox>
                              </Form.Item>
                            </Col>
                            <Col span={2} className="pricingCol" align="center">
                              <Form.Item
                                name={['dealValues', 'dealDays', 6]}
                                valuePropName="checked"
                              >
                                <Checkbox className="bigCheck">
                                  <h6 style={{ display: 'inline' }}>So/Ft</h6>
                                </Checkbox>
                              </Form.Item>
                            </Col>
                          </Row>
                        </Col>
                        <Col span={5} className="pricingCol">
                          <Row>
                            <Col span={24} className="pricingCol">
                              <Form.Item
                                name={['dealValues', 'discountTerms']}
                                id={index}
                              >
                                <Input size="small" placeholder="Anmerkung" />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row>
                            <DirectoryTree
                              checkable
                              selectable={false}
                              multiple
                              showIcon={false}
                              onCheck={(keys) => {
                                dispatch(setTmpCourseId(deal._id));
                                onCheckExisCourses(keys);
                              }}
                              onExpand={onExpand}
                              treeData={treeDataMap}
                              defaultCheckedKeys={deal.standardPricing}
                            />
                          </Row>
                        </Col>
                        <Col span={4} className="pricingCol">
                          <Row
                            style={{
                              alignItems: 'center',
                              height: '100%',
                            }}
                          >
                            <Col
                              span={20}
                              style={{
                                height: '100%',
                              }}
                            >
                              <Row
                                style={{
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  height: '100%',
                                }}
                              >
                                <Button
                                  type="primary"
                                  htmlType="submit"
                                  style={{
                                    minWidth: 85,
                                    minHeight: 40,
                                  }}
                                >
                                  Speichern
                                </Button>
                              </Row>
                            </Col>

                            <Col span={4}>
                              <Popconfirm
                                title="Löschen Sie den Deal"
                                description="Wirklich löschen?"
                                icon={
                                  <QuestionCircleOutlined
                                    style={{
                                      color: 'red',
                                    }}
                                  />
                                }
                                onConfirm={() => deleteDeal(deal._id)}
                                onCancel={cancel}
                                okText="Bestätigen"
                                cancelText="Abbrechen"
                              >
                                <Button
                                  danger
                                  type="primary"
                                  icon={<DeleteFilled />}
                                  size="small"
                                />
                              </Popconfirm>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                );
              }
            })}
          </Content>
        </Layout>
      </Layout>
    </div>
  );
}

export default Aufschlaege;
